import VueAxios from "vue-axios";
import {VuejsDatatableFactory} from "vuejs-datatable";
import axios from "axios";

window._ = require("lodash");

window.queryEncode = function (object) {
    function reducer(obj, parentPrefix = null) {
        return function (prev, key) {
            const val = obj[key];
            key = encodeURIComponent(key);
            const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

            if (val == null || typeof val === 'function') {
                prev.push(`${prefix}=`);
                return prev;
            }

            if (typeof val === 'boolean') {
                prev.push(`${prefix}=${val.toString().toUpperCase()}`);
                return prev;
            }

            if (['number', 'string'].includes(typeof val)) {
                prev.push(`${prefix}=${encodeURIComponent(val)}`);
                return prev;
            }

            prev.push(
                Object.keys(val).reduce(reducer(val, prefix), []).join('&')
            );
            return prev;
        };
    }

    return Object.keys(object).reduce(reducer(object), []).join('&');
};
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
const tableSettings = {
    pager: {
        classes: {
            disabled: "disabled",
            li: "page-item page-link",
            pager: "pagination",
            selected: "active",
        },
        icons: {next: "»", previous: "«"},
    },
    table: {class: "table table-striped table-bordered"},
};

VuejsDatatableFactory.useDefaultType(false)
    .registerTableType("datatable", (e) => e.mergeSettings(tableSettings))
    .registerTableType("ajaxtable", (tableType) =>
        tableType
            .mergeSettings(tableSettings)
            .setDisplayHandler(
                async ({source: baseEndPoint, paged: endpointDesc}) => {
                    console.log("baseEndPoint", baseEndPoint);
                    console.log("endpointDesc", endpointDesc);

                    const res = await axios.get(baseEndPoint);

                    return {
                        rows: res.data.data,
                        totalRowCount: res.data.data.total,
                    };
                }
            )
    );

window.vueDatatable = VuejsDatatableFactory;

try {
    const vue = require("vue").default;
    vue.component(
        "example-component",
        require("./components/ExampleComponent.vue").default
    );

    vue.use(VueAxios, axios);
    vue.use(VuejsDatatableFactory);

    window.Vue = vue;

    require("./bootstrap");
    require("admin-lte");
    window.__statistics__ = require("./statistics").default;
    window.Alpine = require("alpinejs").default;
    Alpine.start();

    let executed = false;
    let loaded = function () {
        if (!executed) {
            $("#page-spinner").fadeOut("slow");
            executed = true;
        }
    };
    $(window).on("load", loaded);
    setTimeout(loaded, 5000);
} catch (e) {
    console.log(e);
}

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
