import Chart from "chart.js/auto";
import moment from "moment";

let chart = null;

export default (props) => ({
    startDate: moment().subtract(29, "days"),
    endDate: moment(),
    orders: {
        loading: false,
        stats: {},
        progress: 0,
    },
    charting: {
        labels: [],
        datasets: [
            {
                key: "orders",
                label: "الطلبات",
                backgroundColor: "rgba(255, 99, 132, 0.2)",
                borderColor: "rgba(255, 99, 132, 1)",
                data: [],
            },
        ],
    },
    chart: null,

    axiosController: null,

    init() {
        const self = this;
        self.setupDateRangePicker();
        self.runStatistics();
        self.setupChart();
        window.addEventListener("beforeunload", () => {
            self.axiosController && self.axiosController.abort();
        });
    },

    async runStatistics() {
        this.resetChart();
        await this.fetchOrders();
        this.updateChart();
    },

    resetChart() {
        this.charting.loading = true;
        this.charting.progress = 0;
        this.charting.labels = [];
        this.charting.datasets.forEach((dataset) => {
            dataset.data = [];
        });
    },

    updateChart() {
        if (chart) {
            this.sortChartLabels();
            this.charting.datasets.find(
                (dataset) => dataset.key === "orders"
            ).data = this.charting.labels.map(
                (label) => this.orders.stats.dataset[label] || 0
            );
            chart.data.labels = this.charting.labels;
            chart.data.datasets = this.charting.datasets;
            chart.update();
        }
    },

    sortChartLabels() {
        const shownInMonths =
            Math.abs(this.endDate.diff(this.startDate, "months")) > 1;
        const format = shownInMonths ? "MMM, YYYY" : "DD MMM, YYYY";
        moment.locale("en");
        this.charting.labels.sort((a, b) => {
            const aDate = moment(a, format);
            const bDate = moment(b, format);
            if (aDate.isBefore(bDate)) {
                return -1;
            }
            if (aDate.isAfter(bDate)) {
                return 1;
            }

            return 0;
        });
    },

    fetchOrders() {
        const self = this;
        self.axiosController = new AbortController();
        self.orders.loading = true;

        const query = {
            startDate: self.transform(self.startDate.format("YYYY-MM-DD")),
            endDate: self.transform(self.endDate.format("YYYY-MM-DD")),
            governorate_id: props.governorate_id,
        };

        const url = "/api/statistics?" + queryEncode(query);

        return axios
            .get(url, {
                signal: self.axiosController.signal,
                timeout: 0,
                onDownloadProgress: (progressEvent) => {
                    self.orders.progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                },
            })
            .then(function (response) {
                self.orders.stats = response.data;
                self.orders.stats.dataset =
                    typeof self.orders.stats.dataset === "string"
                        ? JSON.parse(self.orders.stats.dataset)
                        : self.orders.stats.dataset;
                self.charting.labels = Array.from(
                    new Set([
                        ...self.charting.labels,
                        ...Object.keys(self.orders.stats.dataset),
                    ])
                );
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(function () {
                self.orders.loading = false;
            });
    },

    transform(string) {
        return string.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
    },

    setupDateRangePicker() {
        const self = this;
        setTimeout(() => {
            $('input[name="daterange"]').daterangepicker(
                {
                    opens: "left",
                    startDate: self.startDate,
                    endDate: self.endDate,
                    ranges: {
                        اليوم: [moment(), moment()],
                        آمس: [
                            moment().subtract(1, "days"),
                            moment().subtract(1, "days"),
                        ],
                        "آخر ٧ ايام": [moment().subtract(7, "days"), moment()],
                        "آخر ٣٠ يوم": [moment().subtract(30, "days"), moment()],
                        "الشهر الحالي": [
                            moment().startOf("month"),
                            moment().endOf("month"),
                        ],
                        "الشهر الماضي": [
                            moment().subtract(1, "month").startOf("month"),
                            moment().subtract(1, "month").endOf("month"),
                        ],
                        "السنه الحاليه": [
                            moment().startOf("year"),
                            moment().endOf("year"),
                        ],
                        "السنه الماضيه": [
                            moment().subtract(1, "year").startOf("year"),
                            moment().subtract(1, "year").endOf("year"),
                        ],
                        "الفترة كاملة": [
                            moment().subtract(10, "years").startOf("year"),
                            moment(),
                        ],
                    },
                    locale: {
                        format: "YYYY/MM/DD",
                        applyLabel: "تطبيق",
                        cancelLabel: "إلغاء",
                        fromLabel: "من",
                        toLabel: "إلى",
                        customRangeLabel: "التخصيص",
                        daysOfWeek: [
                            "أحد",
                            "اثنين",
                            "ثلاثاء",
                            "أربعاء",
                            "خميس",
                            "جمعة",
                            "سبت",
                        ],
                        monthNames: [
                            "يناير",
                            "فبراير",
                            "مارس",
                            "أبريل",
                            "مايو",
                            "يونيو",
                            "يوليو",
                            "أغسطس",
                            "سبتمبر",
                            "أكتوبر",
                            "نوفمبر",
                            "ديسمبر",
                        ],
                        firstDay: 1,
                    },
                },
                function (start, end, label) {
                    self.startDate = start;
                    self.endDate = end;
                    self.axiosController.abort();
                    self.runStatistics();
                }
            );
        }, 10);
    },

    setupChart() {
        const config = {
            type: "line",
            data: {
                labels: this.charting.labels,
                datasets: this.charting.datasets,
            },
        };

        setTimeout(() => {
            chart = new Chart(document.getElementById("myChart"), config);
        }, 10);
    },
    createOrder: (evt) => {
        axios
            .post(evt.target.dataset.url)
            .then((res) => {
                toastr.success(evt.target.dataset.message);
            })
            .catch((err) => {
                toastr.error(err.response?.data?.message);
            });
    },
});
